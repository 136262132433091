var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('v-app',[_c('ScrollToTop'),_c('v-main',[_c('div',{staticClass:"main-top"},[_c('b-row',[_c('b-col',{staticClass:"left-side-home",attrs:{"cols":"6"}},[_c('div',{staticClass:"company-text"},[_c('img',{staticClass:"company-logo mb-5",attrs:{"src":"https://bit.ly/3M0yRIT"}}),_c('br'),_c('span',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("welcome_msg")))]),_c('br'),_c('br'),_c('router-link',{staticStyle:{"color":"white","letter-spacing":"2px"},attrs:{"to":"/about"}},[_c('span',[_vm._v(_vm._s(_vm.$t("learn_more")))]),_vm._v("     → ")])],1)]),_c('b-col',{staticClass:"right-side-home",attrs:{"cols":"6"}})],1)],1),_c('v-container',[_c('v-lazy',{attrs:{"options":{
            threshold: 1,
          },"transition":"scroll-y-reverse-transition"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('div',{staticClass:"wrapper mt-10"},[_c('div',{staticClass:"card-wrapper"},[_c('i',{staticClass:"fa-solid fa-briefcase"}),_c('span',{staticClass:"iCountUp"},[_c('ICountUp',{attrs:{"delay":_vm.delay,"endVal":10000,"options":_vm.options}}),_vm._v("+ ")],1),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("cases")))])]),_c('div',{staticClass:"card-wrapper"},[_c('i',{staticClass:"fa-solid fa-people-group"}),_c('span',{staticClass:"iCountUp"},[_c('ICountUp',{attrs:{"delay":_vm.delay,"endVal":5000,"options":_vm.options}})],1),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("clients")))])]),_c('div',{staticClass:"card-wrapper"},[_c('i',{staticClass:"fa-solid fa-flask"}),_c('span',{staticClass:"iCountUp"},[_c('ICountUp',{attrs:{"delay":_vm.delay,"endVal":15,"options":_vm.options}})],1),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("yoe")))])]),_c('div',{staticClass:"card-wrapper"},[_c('i',{staticClass:"fa-solid fa-earth-asia"}),_c('span',{staticClass:"iCountUp"},[_c('ICountUp',{attrs:{"delay":_vm.delay,"endVal":10,"options":_vm.options}})],1),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("countries")))])])])])],1),_c('hr',{staticClass:"horizontal-line my-10"}),_c('div',{staticClass:"expertise-section"},[_c('h1',{staticClass:"services-text"},[_vm._v(_vm._s(_vm.$t("expertise_uppercase")))]),_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"card-grid"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-card',{staticClass:"patent-card expertise"},[_c('v-card-title',{staticClass:"expertise-text"},[_vm._v(_vm._s(_vm.$t("patent"))+" "),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue darken-3 v-card--reveal text-subtitle-2 white--text",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"text-justify text-break"},[_c('p',{staticClass:"truncate-text"},[_vm._v(" "+_vm._s(_vm.$t("patent_1"))+" ")]),_c('router-link',{staticClass:"link",attrs:{"to":"/expertise/patent"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("learn_more")))]),_vm._v("     → ")])],1)]):_vm._e()])],1)],1)]}}])})],1),_c('div',{staticClass:"card-grid"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-card',{staticClass:"trademark-card expertise"},[_c('v-card-title',{staticClass:"expertise-text"},[_vm._v(_vm._s(_vm.$t("trademarks"))+" "),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out green darken-4 v-card--reveal text-subtitle-2 white--text",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"text-justify text-break"},[_c('p',{staticClass:"truncate-text"},[_vm._v(" "+_vm._s(_vm.$t("trademark_1"))+" ")]),_c('router-link',{staticClass:"link",attrs:{"to":"/expertise/trademark"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("learn_more")))]),_vm._v("     → ")])],1)]):_vm._e()])],1)],1)]}}])})],1),_c('div',{staticClass:"card-grid"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-card',{staticClass:"design-card expertise"},[_c('v-card-title',{staticClass:"expertise-text"},[_vm._v(_vm._s(_vm.$t("industrial_design"))+" "),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out pink darken-2 v-card--reveal text-subtitle-2 white--text",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"text-justify text-break"},[_c('p',{staticClass:"truncate-text"},[_vm._v(" "+_vm._s(_vm.$t("design_line_1"))+" ")]),_c('router-link',{staticClass:"link",attrs:{"to":"/expertise/industrial-design"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("learn_more")))]),_vm._v("     → ")])],1)]):_vm._e()])],1)],1)]}}])})],1),_c('div',{staticClass:"card-grid"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-card',{staticClass:"copyright-card expertise"},[_c('v-card-title',{staticClass:"expertise-text"},[_vm._v(_vm._s(_vm.$t("copyright"))+" "),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out yellow accent-3 v-card--reveal text-subtitle-2 black--text",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"text-justify text-break"},[_c('p',{staticClass:"truncate-text"},[_vm._v(" "+_vm._s(_vm.$t("copyright_1"))+" ")]),_c('router-link',{staticClass:"link-copyright",attrs:{"to":"/expertise/copyright"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("learn_more")))]),_vm._v("     → ")])],1)]):_vm._e()])],1)],1)]}}])})],1)])]),_c('Footer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }