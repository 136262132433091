<template>
  <div>
    <Header />
    <v-app>
      <ScrollToTop />
      <v-main>
        <!-- <Slide /> -->
        <div class="main-top">
          <b-row>
            <b-col cols="6" class="left-side-home">
              <div class="company-text">
                <img src="https://bit.ly/3M0yRIT" class="company-logo mb-5" />
                <!-- <span class="title-text">Harmet <span style="color: #c18f3b">&</span> Co</span> -->
                <br />
                <span class="subtitle"> {{ $t("welcome_msg") }}</span>
                <br />
                <br />
                <router-link
                  to="/about"
                  style="color: white; letter-spacing: 2px"
                >
                  <span>{{ $t("learn_more") }}</span>
                  &nbsp; &nbsp; &#8594;
                </router-link>
              </div>
            </b-col>
            <b-col cols="6" class="right-side-home">
              <!-- <div>
                <img src="~@/assets/g20.png" class="wayang" />
              </div> -->
            </b-col>
          </b-row>
        </div>
        <v-container>
          <v-lazy
            v-model="isActive"
            :options="{
              threshold: 1,
            }"
            transition="scroll-y-reverse-transition"
          >
            <div class="wrapper mt-10">
              <div class="card-wrapper">
                <i class="fa-solid fa-briefcase"></i>
                <span class="iCountUp">
                  <ICountUp
                    :delay="delay"
                    :endVal="10000"
                    :options="options"
                  />+
                </span>
                <span class="text">{{ $t("cases") }}</span>
              </div>
              <div class="card-wrapper">
                <i class="fa-solid fa-people-group"></i>
                <span class="iCountUp">
                  <ICountUp :delay="delay" :endVal="5000" :options="options" />
                </span>
                <span class="text">{{ $t("clients") }}</span>
              </div>
              <div class="card-wrapper">
                <i class="fa-solid fa-flask"></i>
                <span class="iCountUp">
                  <ICountUp :delay="delay" :endVal="15" :options="options" />
                </span>
                <span class="text">{{ $t("yoe") }}</span>
              </div>
              <div class="card-wrapper">
                <i class="fa-solid fa-earth-asia"></i>
                <span class="iCountUp">
                  <ICountUp :delay="delay" :endVal="10" :options="options" />
                </span>
                <span class="text">{{ $t("countries") }}</span>
              </div>
            </div>
          </v-lazy>
        </v-container>
        <hr class="horizontal-line my-10" />
        <div class="expertise-section">
          <h1 class="services-text">{{ $t("expertise_uppercase") }}</h1>
          <div class="grid-container">
            <div class="card-grid">
              <v-hover v-slot="{ hover }">
                <v-card class="patent-card expertise">
                  <v-card-title class="expertise-text"
                    >{{ $t("patent") }}
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out blue darken-3 v-card--reveal text-subtitle-2 white--text"
                        style="height: 100%"
                      >
                        <div class="text-justify text-break">
                          <p class="truncate-text">
                            {{ $t("patent_1") }}
                          </p>
                          <router-link to="/expertise/patent" class="link">
                            <strong>{{ $t("learn_more") }}</strong>
                            &nbsp; &nbsp; &#8594;
                          </router-link>
                        </div>
                      </div>
                    </v-expand-transition>
                  </v-card-title>
                </v-card>
              </v-hover>
            </div>
            <div class="card-grid">
              <v-hover v-slot="{ hover }">
                <v-card class="trademark-card expertise">
                  <v-card-title class="expertise-text"
                    >{{ $t("trademarks") }}
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out green darken-4 v-card--reveal text-subtitle-2 white--text"
                        style="height: 100%"
                      >
                        <div class="text-justify text-break">
                          <p class="truncate-text">
                            {{ $t("trademark_1") }}
                          </p>
                          <router-link to="/expertise/trademark" class="link">
                            <strong>{{ $t("learn_more") }}</strong>
                            &nbsp; &nbsp; &#8594;
                          </router-link>
                        </div>
                      </div>
                    </v-expand-transition>
                  </v-card-title>
                </v-card>
              </v-hover>
            </div>
            <div class="card-grid">
              <v-hover v-slot="{ hover }">
                <v-card class="design-card expertise">
                  <v-card-title class="expertise-text"
                    >{{ $t("industrial_design") }}
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out pink darken-2 v-card--reveal text-subtitle-2 white--text"
                        style="height: 100%"
                      >
                        <div class="text-justify text-break">
                          <p class="truncate-text">
                            {{ $t("design_line_1") }}
                          </p>
                          <router-link
                            to="/expertise/industrial-design"
                            class="link"
                          >
                            <strong>{{ $t("learn_more") }}</strong>
                            &nbsp; &nbsp; &#8594;
                          </router-link>
                        </div>
                      </div>
                    </v-expand-transition>
                  </v-card-title>
                </v-card>
              </v-hover>
            </div>
            <div class="card-grid">
              <v-hover v-slot="{ hover }">
                <v-card class="copyright-card expertise">
                  <v-card-title class="expertise-text"
                    >{{ $t("copyright") }}
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out yellow accent-3 v-card--reveal text-subtitle-2 black--text"
                        style="height: 100%"
                      >
                        <div class="text-justify text-break">
                          <p class="truncate-text">
                            {{ $t("copyright_1") }}
                          </p>
                          <router-link
                            to="/expertise/copyright"
                            class="link-copyright"
                          >
                            <strong>{{ $t("learn_more") }}</strong>
                            &nbsp; &nbsp; &#8594;
                          </router-link>
                        </div>
                      </div>
                    </v-expand-transition>
                  </v-card-title>
                </v-card>
              </v-hover>
            </div>
          </div>
        </div>
        <!-- <v-container>
          <v-lazy v-model="isActive" :options="{
            threshold: 1,
          }" transition="scroll-y-reverse-transition">
          </v-lazy>
          <div class="partner-wrapped">
            <h1 class="text-center mb-5">{{ $t("our_partners") }}</h1>
            <div class="company-list">
              <div class="company-item">
                <v-img class="partner" src="~@/assets/partner/star_vision.jpg"></v-img>
                <v-img class="partner" src="~@/assets/partner/advan.jpg"></v-img>
                <v-img class="partner" src="~@/assets/partner/roma_1927.jpg"></v-img>
                <v-img class="partner" src="~@/assets/partner/evercoss.jpg"></v-img>
                <v-img class="partner" src="~@/assets/partner/avaro.jpg"></v-img>
              </div>

              <div class="company-item">
                <v-img class="partner" src="~@/assets/partner/fore.jpg"></v-img>
                <v-img class="partner" src="~@/assets/partner/jakarta_aquarium.jpg"></v-img>
                <v-img class="partner" src="~@/assets/partner/blue_gaz.jpg"></v-img>
                <v-img class="partner" src="~@/assets/partner/mortar_utama.jpg"></v-img>
                <v-img class="partner" src="~@/assets/partner/gmc.jpg"></v-img>
              </div>
            </div>
          </div>
        </v-container> -->
        <Footer />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
// import Slide from "@/components/Slide.vue";
// import Card from "@/components/Card.vue";
import Footer from "@/components/Footer.vue";
import ICountUp from "vue-countup-v2";
import ScrollToTop from "@/components/ScrollToTop.vue";

export default {
  name: "Home",
  components: {
    Header,
    // Slide,
    Footer,
    ICountUp,
    ScrollToTop,
  },
  data() {
    return {
      reveal: false,
      isActive: false,
      interval: 5000,
      number: 0,
      delay: 1000,
      cases: 9000,
      endVal: 120500,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
        duration: 5,
      },
      scTimer: 0,
      scY: 0,
    };
  },
  // mounted() {
  //   window.addEventListener('scroll', this.scrollFunction);
  // },
  // destroyed() {
  //   window.addEventListener('scroll', this.scrollFunction);
  // },
  methods: {
    // scrollFunction() {
    //   var mybutton = document.getElementById("myBtn");
    //   if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    //     mybutton.style.display = "block";
    //   } else {
    //     mybutton.style.display = "none";
    //   }
    // },
    // topFunction() {
    //   window.scrollTo({
    //     top: 0,
    //   })
    // }
    // handleScroll: function () {
    //   if (this.scTimer) return;
    //   this.scTimer = setTimeout(() => {
    //     this.scY = window.scrollY;
    //     clearTimeout(this.scTimer);
    //     this.scTimer = 0;
    //   }, 100);
    // },
    // toTop: function () {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth"
    //   });
    // },
  },
};
</script>

<style scoped>
/* #myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 7px;
  border: none;
  outline: none;
  background-color: rgb(140, 61, 64);
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
}

#myBtn:hover {
  background-color: #21242b;
} */

.left-side-home {
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: end;
  align-items: center;
}

.right-side-home {
  height: 100vh;
}

.row {
  margin: 0;
}

.row-top {
  height: 100vh;
}

.wayang {
  width: 50%;
  height: 50%;
}

.main-top {
  width: 100%;
  height: 100vh;
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(147, 67, 67, 1) 0%,
    rgba(111, 27, 27, 1) 90%
  );
  /* background: url("~@/assets/bg-home.jpg") no-repeat; */
  /* background-size: cover; */
  /* background-color: #00000080; */
  /* background-blend-mode: soft-light; */
}

.company-logo {
  width: 40%;
}

.company-text {
  /* position: absolute;
  top: 14%;
  left: 10%; */
  color: white;
  max-width: 80%;
  /* font-weight: bold; */
  /* transform: translate(-50%, -50%); */
}

.title-text {
  font-size: 3em;
  font-weight: bold;
  /* font-family: "Steelfish", sans-serif; */
}

.subtitle {
  font-size: 1em;
  /* font-family: "Steelfish", sans-serif; */
}

/* .main-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 10;
} */

.text-company {
  line-height: 2;
}

.bg-main-image {
  display: absolute;
  height: 150%;
  width: 200%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.main-image-wrapped {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-image {
  height: 80%;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.horizontal-line {
  width: 85%;
  margin: 0 auto;
}

span.iCountUp {
  color: #e0e0e0;
  font-size: 2em;
  text-align: center;
  padding: 0.7em 0;
  font-weight: 600;
  line-height: 0;
}

.company-profile {
  max-width: 75%;
  margin: 0 auto;
}

.wrapper {
  margin: 0 auto;
  width: 80vw;
  justify-content: space-around;
  gap: 10px;
  display: flex;
}

.card-wrapper {
  min-width: 13em;
  min-height: 28vmin;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em 0;
  font-size: 16px;
  border-radius: 1.5em;
  background-color: #21242b;
  border-bottom: 10px solid #8f3d40;
}

.truncate-text {
  display: -webkit-box;
  max-width: 250px;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

i {
  color: #c18f3b;
  font-size: 2.5em;
  text-align: center;
}

span.num {
  color: #ffffff;
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 3em;
}

span.text {
  color: #e0e0e0;
  font-size: 1em;
  text-align: center;
  padding: 0.7em 0;
  font-weight: 400;
  line-height: 0;
}

.services-text {
  color: #ffffff;
  font-size: 2em;
  text-align: center;
  padding: 0.7em 0;
  margin: 3% auto;
  font-weight: 600;
  line-height: 0;
  letter-spacing: 10px;
}

.expertise-section {
  background: url("~@/assets/background-expertise.webp") no-repeat;
  background-position: right 65% top 50%;
  background-size: cover;
  padding: 10px;
  margin: 0 auto 15%;
  width: 100vw;
  height: 50vh;
}

.grid-container {
  display: flex;
  flex-flow: row wrap;
  padding: 0 5%;
}

.card-grid {
  width: 25%;
}

.expertise {
  border-radius: 0%;
  height: 70vh;
}

.fa-lightbulb {
  font-size: 1em;
}

.expertise-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  font-family: "Steelfish", sans-serif;
  height: 50vh;
}

/* .plus-sign {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 40vh;
  color: white;
  font-size: 5em;
  padding-right: 8%;
  font-family: "Steelfish", sans-serif;
} */
.link {
  color: #ffffff;
}

.link-copyright {
  color: #000000;
}

.patent-card {
  background: url("~@/assets/patent-bg.jpg") no-repeat;
  background-size: cover;
  width: 100vw;
  height: 50vh;
  overflow: hidden;
  background-color: #143990b3;
  background-blend-mode: soft-light;
  color: white;
}

.trademark-card {
  background: url("~@/assets/trademark-bg.jpg") no-repeat;
  background-size: cover;
  width: 100vw;
  height: 50vh;
  overflow: hidden;
  background-color: #1b8d36b3;
  background-blend-mode: soft-light;
  color: white;
}

.design-card {
  background: url("~@/assets/industrial-design-bg.jpg") no-repeat;
  background-size: cover;
  width: 100vw;
  height: 50vh;
  overflow: hidden;
  background-color: #c606c3b3;
  background-blend-mode: soft-light;
  color: white;
}

.copyright-card {
  background: url("~@/assets/copyright-bg.jpg") no-repeat;
  background-size: cover;
  width: 100vw;
  height: 50vh;
  overflow: hidden;
  background-color: #b9b918b3;
  background-blend-mode: soft-light;
  color: white;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;
  padding: 5%;
}

.partner-wrapped {
  margin-bottom: 3%;
}

.company-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.v-image__image {
  background-size: contain !important;
}

.partner {
  width: 1%;
  height: 1%;
  margin: 1em;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .wrapper {
    width: 85vw;
  }

  /* .card-wrapper {
    height: 40vmin;
    width: 40vmin;
    font-size: 12px;
  } */

  .card-wrapper {
    min-height: 0;
    min-width: 0;
    height: 23vmin;
    width: 13em;
  }

  .card-grid {
    width: 50%;
  }

  .expertise {
    height: 25vh;
  }

  .patent-card {
    height: 25vh;
  }

  .expertise-text {
    height: 25vh;
  }

  .expertise-section {
    margin: 0px auto 18%;
  }

  .truncate-text {
    -webkit-line-clamp: 7;
  }
}

@media screen and (max-width: 768px) {
  .company-text {
    max-width: 60%;
  }

  .title-text {
    font-size: 4em;
  }

  .wrapper {
    width: 90vw;
    flex-wrap: wrap;
    gap: 30px;
  }

  .card-wrapper {
    width: calc(25% - 25px);
    height: 25vmin;
    font-size: 14px;
    min-height: 0;
    min-width: 0;
  }

  .card-grid {
    width: 50%;
  }

  .expertise {
    height: 25vh;
  }

  .expertise-text {
    height: 25vh;
  }

  .truncate-text {
    -webkit-line-clamp: 5;
  }

  .expertise-section {
    margin: 0 auto 20%;
  }
}

@media screen and (max-width: 480px) {
  /* home page */
  .company-text {
    top: 11%;
    text-align: justify;
    max-width: 80%;
  }

  .expertise-section {
    margin: 0 auto 50%;
  }

  .grid-container {
    padding: 3% 5%;
  }

  .wrapper {
    gap: 15px;
    margin: 0 auto;
  }

  .card-wrapper {
    /* width: calc(100% - 40px); */
    width: calc(100% - 220px);
    height: 25vmin;
    font-size: 8px;
  }

  .card-grid {
    width: 100%;
  }

  .expertise-text {
    height: 15vh;
  }

  .expertise {
    height: 15vh;
  }

  .v-card--reveal {
    padding: 5%;
  }

  .truncate-text {
    -webkit-line-clamp: 3;
  }
}
</style>
