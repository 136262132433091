<template>
  <nav class="navbar fixed-top navbar-expand-lg" :class="{ change_color: scrollPosition > 100 }" id="nav">
    <!-- <v-container> -->
    <!-- <router-link to="/" class="navbar-brand"><img src="https://bit.ly/3M0yRIT" height="80px" /></router-link> -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon">
        <i class="fas fa-bars" style="color: #fff; font-size: 28px"></i>
      </span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto" :class="{ navbar_text: scrollPosition > 100 }">
        <li class="nav-item">
          <router-link class="nav-link" :class="{ text_color: scrollPosition > 100 }" to="/">{{ $t("home") }}
          </router-link>
        </li>
        <li class="nav-item dropdown">
          <router-link to="/expertise" class="nav-link" :class="{ text_color: scrollPosition > 100 }">
            <span class="dropdown-toggle" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              {{ $t("expertise") }}
            </span>
          </router-link>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <router-link to="/expertise/patent">
              <span class="dropdown-item">{{
                  $t("expertise_1")
              }}</span>
            </router-link>
            <router-link to="/expertise/trademark"><span class="dropdown-item">{{
                $t("expertise_2")
            }}</span></router-link>
            <router-link to="/expertise/industrial-design"><span class="dropdown-item">{{
                $t("expertise_3")
            }}</span></router-link>
            <router-link to="/expertise/copyright"><span class="dropdown-item">{{
                $t("expertise_4")
            }}</span></router-link>
          </div>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :class="{ text_color: scrollPosition > 100 }" to="/our-people">{{
              $t("our_people")
          }}</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :class="{ text_color: scrollPosition > 100 }" to="/about">{{ $t("about") }}
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  name: "Header",
  data() {
    return {
      scrollPosition: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    changeLocale(locale) {
      i18n.locale = locale;
    },
  },
};
</script>
<style scoped>
.router-link-exact-active {
  color: #c18f3b !important;
}

.change_color {
  background-color: rgb(140, 61, 64);
}

.text_color {
  color: white;
}

.navbar_text li:after {
  content: "";
  display: block;
  border-bottom: 2px solid white;
  transform: scaleX(0);
  transition: 0.2s ease-in-out;
}

.navbar_text li:hover:after {
  transform: scaleX(1);
}

a {
  color: #ffffff;
}

#nav {
  text-align: center;
}

.navbar {
  transition: 0.5s;
}

.navbar-toggler {
  border: 1px solid #c7933e;
}

.navbar-nav li:after {
  content: "";
  display: block;
  border-bottom: 2px solid rgb(140, 61, 64);
  transform: scaleX(0);
  transition: 0.2s ease-in-out;
}

.navbar-nav li:hover:after {
  transform: scaleX(1);
}

.nav-link {
  font-size: 20px;
  margin-right: 20px;
  transition: none !important;
  color: #ffffff;
  cursor: pointer;
}

.nav-link:hover {
  color: #c18f3b !important;
}

.locale-wrapped {
  display: flex;
  justify-content: center;
}

.dropdown:hover>.dropdown-menu {
  display: block;
}

.dropdown>.dropdown-toggle:active {
  pointer-events: none;
}

.dropdown-item:hover {
  color: white !important;
  background-color: rgb(140, 61, 64);
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .show {
    background: rgba(255, 255, 255, 0.26);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15.9px);
    -webkit-backdrop-filter: blur(15.9px);
  }

  .locale-switcher {
    max-width: 25%;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .show {
    background: rgba(255, 255, 255, 0.26);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15.9px);
    -webkit-backdrop-filter: blur(15.9px);
  }

  .locale-switcher {
    max-width: 25%;
  }
}

@media screen and (max-width: 480px) {
  .show {
    background: rgba(255, 255, 255, 0.26);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15.9px);
    -webkit-backdrop-filter: blur(15.9px);
  }

  .locale-switcher {
    max-width: 25%;
  }
}

/* a.nav-link:hover {
  color: white !important;
  background-color: rgb(140, 61, 64);
  border-radius: 10px !important;
} */
</style>
